import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import fuji from '../images/fuji_sagres.resized.jpg'
import vilano from '../images/vilano.resized.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        aria-label="close"
        role="button"
        tabIndex={0}
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyPress={event => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span id="aboutimg" className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            I was born and raised in Chicago, Illinois. I first began trying to
            code when I was 14 years old. However, I had difficulty learning on
            my own and my high school did not have the resources to provide
            programming courses. In high school I also had a hard time deciding what to major in,
            since I wanted to go to college for STEM but grew more and more weary of physics
            and chemistry. In the end, I decided to try CS in the hopes that I would thrive in a
            classroom environment that I never had access to before. Sure enough,
            it all worked out for the best!
          </p>
          <p>
            I am now a recent grad from the University of Minnesota, with a B.S.
            in Computer Science and a minor in Statistics. In my final few
            semesters of school, I decided to focus my upper division courses on
            data-driven computing, which focuses on disciplines such as data
            science, big data, and GIS. Throughout the early stages of my career,
            I have mostly gained experience in backend development. I really enjoy
            the process of designing, implementing, and analyzing performant systems.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work Experience</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <h3 className="company">IDeaS - A SAS Company</h3>
          <p className="dates">Associate Application Developer: June 2021 - Present</p>
          <ul>
            <li>
              "IDeaS, a SAS company, is the world’s leading provider of revenue
              management software and services. With over 30 years of expertise,
              IDeaS drives better revenue for more than 18,000 clients in 140
              countries."
            </li>
            <li>
              Java, Spring Boot, Amazon Web Services, MongoDB/Redis, RabbitMQ, Terraform, Javascript, Docker, Jenkins, Gerrit, OpenAPI, Atlassian Products (Bitbucket, Confluence, Jira)
            </li>
          </ul>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <h3 className="company">New York City Cyber Command</h3>
          <p className="dates">Data Engineer Intern: February - April 2021</p>
          <ul>
            <li>
            Supported the development, assessment, and optimization of the agency's ETL pipeline, handling security logs from 20+ external sources from over 100 municipal agencies across NYC.
            </li>
            <li>
            Implemented a dashboard for the SRE Team, pulling, aggregating, and presenting analytics from GCP in order to communicate the health of the data pipeline to executives and the agency more broadly.
            </li>
            <li>
            Debugged batch processing modules, allowing more consistent data to be sent to the data warehouse, enabling analysts to ensure integrity of city systems.
            </li>
            <li>
            Assisted data engineers with performing code review, QA testing, and deployment of performance improvements across the pipeline via CI/CD process.
            </li>
            <li>
            Python, Javascript, Flask, Java, Terraform, Jenkins, Google Cloud Platform, Git, Jira
            </li>
          </ul>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <h3 className="company">Mzuzah Charitable</h3>
          <p className="dates">Web Design Intern: November 2020 - April 2021</p>
          <ul>
            <li>
            Redesigned and streamlined company website using Squarespace, adding custom HTML/CSS and Javascript as needed in order to meet the company's specific needs.
            </li>
            <li>
            Assisted web design team in wireframing, prototyping, and creating new content for the overhauled website, as well as optimizing for mobile users, the company's main medium of engagement.
            </li>
            <li>
            Implemented search engine optimizations and transformed the donation process to increase the organization's footprint and revenue.
            </li>
          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'passions' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Passions</h2>
          <h3 className="company">Hobbies</h3>
          <p className="dates">How I spend my free time</p>
          <p>
            Since graduating from school, I've been trying to pick up where I left
            off with learning Spanish. I am definitely rusty and it is hard
            to find the time for it, but I am making a conscious effort to expose myself
            to the language through things like TV shows and music. Also on my bucket list is
            to learn how to draw. I've been looking for a hobby that is less cerebral than what I
            usually find myself doing, and thought it would be a great creative outlet. Still, a
            surprising amount of technique to learn.
          </p>
          <h3 className="company">Music Suggestions</h3>
          <p className="dates">My recent listens</p>
          <p>
            Over the past year or so, a lot of the
            music I have come to enjoy includes the following: Funkadelic, Black
            Sabbath, St.Vincent, Sufjan Stevens, Beach House, Death Grips, and Grimes. I am also a big
            fan of live music, and am hoping to develop a website in the near future
            that helps people discover upcoming shows in their area.
          </p>

          <h3 className="company">Spotitheta</h3>
          {/* <p className="dates">Link to live site (in progress)</p> */}
          <p>
            Continuing with music, late 2020 I decided to begin working on a project to
            help people quantitatively compare their music tastes.
            Using Spotify's Web API along with Vue/Express frameworks,
            calculate a "genre vector" for the user based off their recent
            listening history, and let them directly see how similar their
            recent music taste is to any other user that has used the app via
            cosine similarity, essentially finding the angle between two user
            genre vectors to determine how similar they are.
          </p>
          <h3 className="company">In Memoriam</h3>
          <p className="dates">"What is grief if not love persevering"</p>
          <p>
            One of my favorite things to do during the 4 months of warm weather
            is biking in the city. The following section is dedicated to those
            who have fallen in pursuit of this summer pastime.
          </p>
          <span id="bikeimg" className="image body">
            <img src={fuji} alt="" />
          </span>
          <p className="dates">1986 Fuji Sagres: Hit by car (not my fault)</p>
          <p className="dates">1986 - 7/7/2020</p>
          <span id="bikeimg" className="image body">
            <img src={vilano} alt="" />
          </span>
          <p className="dates">
            Cheap amazon bike: Stolen (my fault)
          </p>
          <p className="dates">5/19/2017 - 8/31/2018</p>
          {close}
        </article>

        {/* CONTACT NOT LIVE RIGHT NOW, NEED TO FIGURE OUT PHP/CONTACT FORM SENDING */}
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
            Currently, the best way to contact me is through LinkedIn.
          </p>
          <form method="POST" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input disabled type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input disabled type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                disabled
                name="message"
                id="message"
                rows="4"
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                  disabled
                  type="submit"
                  value="Send"
                  className="special"
                />
              </li>
              <li>
                <input disabled type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/jmfay/"
                className="icon fa-linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/jmfay"
                className="icon fa-github"
                target="_blank"
                rel="noreferrer"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a className="icon fa-envelope" href="mailto:jmfay33@gmail.com">
                <span className="label">E-Mail</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
